import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";
import Gauge1 from "../charts/Gauge1";
import GaugeChart from "react-gauge-chart";
import Gauge2 from "../charts/Gauge2";
import Thermometer from "react-thermometer-component";
import Decrypt from "./Decrypt";
import Dec2bin from "./Dec2bin";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };
  const [ison, setIson] = useState(false);

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  // console.log(resultlatest);

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setResultLatest(resultlatest);
    setMqttData(mqttdata);
    setIson(
      mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][39] === 1
          ? true
          : false
        : false
    );
  }, 5000);
  // console.log(ison);

  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];

  let [flowratearr, setflowratearr] = useState([]);
  let [totalizedflow, setTotalizedflow] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  // console.log(convertTo24Hour(currentTime));

  useEffect(() => {
    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers }
      )
      .then(function (response) {
        console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);
        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));

          let st = Number(message[0][144]);
          let nd = Number(message[0][145]);
          let rd = Number(message[0][146]);
          let th = Number(message[0][147]);

          let value = Decrypt(
            Dec2bin((st * 256 + nd) * 65536 + (rd * 256 + th))
          );
          // console.log(value);

          // console.log(message);

          //to decrpt
          let array_index = 0;
          msgArray = [];

          for (let index = 0; index < 50; index++) {
            array_index = index * 4;
            // msgArray[index] =
            //   Number(message[i][array_index]) * 256 +
            //   Number(message[i][array_index + 1]);

            let st = Number(message[i][array_index]);
            let nd = Number(message[i][++array_index]);
            let rd = Number(message[i][++array_index]);
            let th = Number(message[i][++array_index]);
            msgArray[index] = Decrypt(
              Dec2bin((st * 256 + nd) * 65536 + (rd * 256 + th))
            );

            dataArr[i] = msgArray;
            // console.log(dataArr);
          }
          // dataArr[i] = msgArray;
        }
        setTimeArray(timeArray);
        // console.log(timeArray);
        // console.log(dataArr);
        for (let i = 0; i < dataArr.length; i++) {
          totalizedflow[i] = dataArr[i][39] / 1000; //volume total - m cube
          flowratearr[i] = dataArr[i][37]; //volume flow rate
        }

        setTotalizedflow(totalizedflow);
        setflowratearr(flowratearr);

        // console.log("hello from axios");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);

  // console.log(dataArr);

  // console.log(totalizedflow[0]);
  let [firstNonZeroValue, setFirstnonzerovalue] = useState([]);
  for (let k = 0; k < totalizedflow.length; k++) {
    if (totalizedflow[k] !== 0) {
      firstNonZeroValue = totalizedflow[k];
      break;
    }
    // setFirstnonzerovalue(firstNonZeroValue);
  }
  // console.log(firstNonZeroValue);

  // console.log(totalizedflow);
  // console.log(totalizedflow);
  // console.log(flowratearr);

  let prunedTotalizedflow = [];
  let prunedflowrate = [];
  let prunedTotalizedTime = [];
  let prunedFlowTime = [];

  // new optimized array
  for (let i = 0; i < timeArray.length / 5; i++) {
    let trueIndex = 0;

    trueIndex = i * 5;

    prunedTotalizedflow.push(totalizedflow[trueIndex]);
    prunedTotalizedTime.push(timeArray[trueIndex]);

    prunedflowrate.push(flowratearr[trueIndex]);
    prunedFlowTime.push(timeArray[trueIndex]);
  }

  let hourlyTotalized = [];
  let hourlyTotalizedTime = [];

  for (let i = 0; i < totalizedflow.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, totalizedflow.length - 1);
    hourlyTotalized.push(
      (totalizedflow[end] - totalizedflow[start]).toFixed(2)
    );
    hourlyTotalizedTime.push(timeArray[end]);
  }

  let totalized5min = [];
  let totalized5minTime = [];
  for (let i = 0; i < totalizedflow.length - 1; i += 5) {
    const start = Math.max(0, i - 5);
    const end = Math.min(i + 5, totalizedflow.length - 1);
    totalized5min.push((totalizedflow[end] - totalizedflow[start]).toFixed(2));
    totalized5minTime.push(timeArray[end]);
  }
  // console.log(totalized5min);
  // console.log(totalized5minTime);

  // console.log(prunedTotalizedflow);
  // console.log(prunedflowrate);

  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  for (let i = 0; i < flowratearr.length; i++) {
    if (flowratearr[i] === {}) {
      flowratearr[i] = 0;
    }
  }
  // console.log(flowratearr);

  const newArray = prunedflowrate.map((item) =>
    typeof item === "object" ? 0 : item
  );

  let totalFlow = mqttdata?.[panelinfo.cardid]
    ? (
        (Number(mqttdata[panelinfo.cardid][0]) * 65536 +
          Number(mqttdata[panelinfo.cardid][1])) /
        100
      ).toFixed(2)
    : 0;

  return (
    <div className="flex h-screen bg-gradient-to-br from-black via-green-700 to-[#139c13] overflow-hidden">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 bg-[#101827] p-1  flex flex-wrap justify-end items-center gap-2 ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-3 py-1  border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>

          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            <WelcomeBanner />
            <div>
              <h3 className="text-center font-semibold font-mono text-sm  text-white m-2">
                ( {name_list[panelinfo.cardid]} )
              </h3>
            </div>

            {/* statuses cards */}
            {/* <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap  font-sans shadow-cyan-500">
              <div className="flex flex-wrap "></div>
            </div> */}
            {/* status ends here */}

            {/* //value Cards  */}
            <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap  font-sans shadow-[#00FF00]">
              {/* voltage & phase voltage divs - 8 */}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    VOLUME TOTAL
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][39] / 1000
                        : 0}{" "}
                      m <sup>3</sup>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    VOLUMETRIC FLOW RATE
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][37]
                        : 0}{" "}
                      L/s
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    MASS TOTAL
                  </div>
                  <div>
                    {/* <span className="font-bold text-xl">
                      {totalFlow >= firstNonZeroValue
                        ? (
                            (mqttdata?.[panelinfo.cardid]
                              ? (
                                  (Number(mqttdata[panelinfo.cardid][0]) *
                                    65536 +
                                    Number(mqttdata[panelinfo.cardid][1])) /
                                  100
                                ).toFixed(2)
                              : 0) - firstNonZeroValue
                          ).toFixed(3)
                        : "INVALID DATA"}{" "}
                      m <sup>3</sup>
                    </span> */}
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][38] / 1000).toFixed(2)
                        : 0}{" "}
                      ton
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    MASS FLOW RATE
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][34]
                        : 0}{" "}
                      Kg/h
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    DENSITY
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][35]
                        : 0}{" "}
                      g/cm<sup>3</sup>
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    LEFT COIL VOLTAGE
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][43]
                        : 0}{" "}
                      mV{/* m <sup>3</sup> */}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    RIGHT COIL VOLTAGE
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][44]
                        : 0}{" "}
                      mV
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    DRIVE COIL VOLTAGE
                  </div>
                  <div>
                    {/* <span className="font-bold text-xl">
                      {totalFlow >= firstNonZeroValue
                        ? (
                            (mqttdata?.[panelinfo.cardid]
                              ? (
                                  (Number(mqttdata[panelinfo.cardid][0]) *
                                    65536 +
                                    Number(mqttdata[panelinfo.cardid][1])) /
                                  100
                                ).toFixed(2)
                              : 0) - firstNonZeroValue
                          ).toFixed(3)
                        : "INVALID DATA"}{" "}
                      m <sup>3</sup>
                    </span> */}
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][45]
                        : 0}{" "}
                      %
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    TEMPERATURE
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][36]
                        : 0}{" "}
                      °C
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-green-900 to-green-700 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    INTERNAL TEMPERATURE
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][51]
                        : 0}{" "}
                      °C
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-[#101827] hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    Current value on the current output
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][40]
                        : 0}{" "}
                      mA
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-[#101827] hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    Frequency of signal on the pulse output
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][41]
                        : 0}{" "}
                      Hz
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-[#101827] hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase text-center text-lg">
                    Measuring Tubes Oscillation Frequency
                  </div>
                  <div>
                    <span className="font-bold text-xl text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][42]
                        : 0}{" "}
                      Hz
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* value ends here */}

            <div className="flex mb-2 justify-center items-center flex-wrap gap-3 ">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg  ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  FLOW RATE TREND (Volumetric)
                </div>
                <Linechart
                  dataArr={newArray}
                  timeArr={prunedFlowTime}
                  divider={1}
                  tag="Flow Rate(L/s)"
                  colour="#00FF00"
                  min={0}
                  max={10}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                />
              </div>
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2  rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  VOLUME TREND ( m<sup>3</sup> )
                </div>
                <Linechart
                  dataArr={prunedTotalizedflow}
                  timeArr={prunedTotalizedTime}
                  divider={1}
                  tag="Volume(L)"
                  colour="#00FF00"
                  min={0}
                  max={10}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                />
              </div>
            </div>
            <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  VOLUME / HOUR
                </div>

                <ProgressChart
                  dataArr={hourlyTotalized}
                  timeArr={hourlyTotalizedTime}
                  colour={"#00FF00"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Volume Per Hour"}
                />
              </div>
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-[#00FF00]  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  VOLUME PER 5 MINS
                </div>

                <ProgressChart
                  dataArr={totalized5min}
                  timeArr={totalized5minTime}
                  colour={"#00FF00"}
                  date={selectedDate}
                  name={name_list[panelinfo.cardid]}
                  tag={"Volume per 5Mins"}
                />
              </div>
              {/* <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  Hot Air Oven Trend
                </div>
                <Linechart
                  dataArr={chamb4prunedArr}
                  timeArr={chamb4prunedTime}
                  divider={1}
                  tag="Hot Air Oven Temp"
                  colour="#38B2AC"
                  min={0}
                  max={1}
                  date={selectedDate}
                />
              </div> */}
            </div>
            {/* <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-serif p-2">
                  Energy<sub> / Hour</sub> Trend
                </div>
                <Barchart
                  dataArr={energyPerHour}
                  timeArr={timeHour}
                  divider={1}
                  tag="Energy"
                  colour="#38B2AC"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div> */}
            {/* charts end here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
