import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
import Smsalert from "./Smsalert";

const Home = () => {
  const cards = [];
  const options = [];

  const disconnectString = localStorage.getItem("disconnect");
  const disconnect = eval("(" + disconnectString + ")");

  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);
    // }, [mqttdata]);
  }, 2000);

  // console.log(mqttdata);

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  let project_name = JSON.parse(localStorage.getItem("project_name"));

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }
  // imeilist.length
  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className=" w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 p-10 mt-2 shadow-[#00FF00] ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 
    max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer flex-grow bg-white bg-opacity-25 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 "
      >
        <div className="border-b-4 border-[#22ad22] mb-5">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}
          >
            <h2 className="text-center font-semibold font-sans text-xl pb-1 text-white">
              {location_list[i]}
            </h2>
            <h3 className="text-center font-semibold font-mono text-sm pb-1 text-white">
              ( {name_list[i]} )
            </h3>
            <div className="text-center font-semibold font-mono text-xs pb-2 text-white">
              ({imeilist[i]})
            </div>
          </Link>
        </div>

        <div className="flex flex-wrap gap-2 justify-center items-center align-middle">
          <div className="p-2 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm  h-full">
            VOLUME
            <div className="p-2 m-1 text-white bg-gradient-to-br from-green-800 to-green-600 hover:text-white rounded-lg shadow-xl  transition duration-300 ease-in-out focus:outline-none block  font-bold text-sm ">
              <div></div>
              <span>
                {mqttdata?.[i] ? (mqttdata[i][39] / 1000).toFixed(2) : 0} KL
                {/* m <sup>3</sup> */}
              </span>
            </div>
          </div>
          <div className="p-2 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm h-full ">
            VOLUMETRIC FLOW RATE
            <div className="p-2 m-1 text-white bg-gradient-to-br from-green-800 to-green-600 hover:text-white rounded-lg shadow-xl  transition duration-300 ease-in-out focus:outline-none block  font-bold text-sm">
              <div></div>
              <span>{mqttdata?.[i] ? mqttdata[i][37] : 0} L/s</span>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 justify-center items-center align-middle">
          <div className="p-2 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm  h-full">
            MASS TOTAL
            <div className="p-2 m-1 text-white bg-gradient-to-br from-green-800 to-green-600 hover:text-white rounded-lg shadow-xl  transition duration-300 ease-in-out focus:outline-none block  font-bold text-sm ">
              <div></div>
              <span>
                {mqttdata?.[i] ? mqttdata[i][38] : 0} Kg
                {/* m <sup>3</sup> */}
              </span>
            </div>
          </div>
          <div className="p-2 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm h-full ">
            MASS FLOW RATE
            <div className="p-2 m-1 text-white bg-gradient-to-br from-green-800 to-green-600 hover:text-white rounded-lg shadow-xl  transition duration-300 ease-in-out focus:outline-none block  font-bold text-sm">
              <div></div>
              <span>{mqttdata?.[i] ? mqttdata[i][34] : 0} Kg/h</span>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-wrap justify-center items-center ">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm  ">
            Incubator-3
            <p className="p-1 m-1 text-white bg-gradient-to-br from-cyan-800 to-cyan-600 hover:text-white rounded-lg shadow-xl  transition duration-300 ease-in-out focus:outline-none block  font-bold text-sm">
              <div>Temperature</div>
              <span>{mqttdata?.[i] ? mqttdata[i][100] / 10 : 0} ° C</span>
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-gray-100 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm  ">
            Hot Air Oven
            <p className="p-1 m-1 text-white bg-gradient-to-br from-cyan-800 to-cyan-600 hover:text-white rounded-lg shadow-xl  transition duration-300 ease-in-out focus:outline-none block  font-bold text-sm">
              <div>Temperature</div>
              <span>{mqttdata?.[i] ? mqttdata[i][150] / 10 : 0}° C</span>
            </p>
          </p>
        </div> */}

        <Link
          to="/Dashboard"
          onClick={() => handleClick(imeilist[i], device_ids[i], i)}
        >
          <div className="text-center mt-2">
            <button className="btn btn-primary p-2 bg-green-700 text-white shadow-lg shadow-black">
              Show more..
            </button>
          </div>
        </Link>
      </div>
    );
  }

  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  return (
    <div className="mt-0  ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex p-2 bg-black text-center font-sans font-semibold  justify-end flex-wrap gap-6 ">
        <nav className="text-white text-xl">FLOW DATA LOGGER</nav>
        <div className="flex justify-end">
          <Select options={options} onChange={handleChange} />
          <Link
            className="flex items-center px-3 py-1 text-sm font-medium text-[#00FF00] hover:text-white"
            to="/"
            onClick={() => {
              // disconnect();
              // localStorage.clear();
            }}
          >
            Log Out
          </Link>
        </div>
      </div>

      <div className="min-h-screen  bg-gradient-to-br from-black via-green-700 to-[#139c13] overflow-hidden ">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>

      {/* <div>
        <Smsalert />
      </div> */}
    </div>
  );
};

export default Home;
